<template>
  <div class="input_wrapper">
    <input
      :type="field_type"
      v-model="content"
      class="form-control"
      :style="custom_style"
      @keyup.13="enter_key_pressed"
    />
    <i class="fa fa-eye" :class="{ 'fa-eye-slash': show_text }" @click="show_text = !show_text"></i>
  </div>
</template>
<script type="text/javascript">
export default {
  props: ['value', 'custom_style'],
  data() {
    return {
      show_text: false,
      content: '',
    };
  },
  computed: {
    field_type() {
      return this.show_text ? 'text' : 'password';
    },
  },
  methods: {
    enter_key_pressed() {
      this.$emit('enter_key_pressed');
    },
  },
  watch: {
    value() {
      this.content = this.value;
    },
    content() {
      this.$emit('input', this.content);
    },
  },
};
</script>
<style type="text/css" scoped>
.input_wrapper {
  position: relative;
}
i {
  position: absolute;
  right: 5px;
  color: black;
  top: 10px;
  z-index: 5;
  cursor: pointer;
}
input {
  padding-right: 30px;
}
</style>
