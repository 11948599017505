<template>
  <div class="login-page">
    <div class="login-box">
        <div class="logo-container">
          <img src="../../assets/images/logo_ghrs.png" />
        </div>
      <div class="form-group">
        <label>Usuário: </label>
        <input type="text" class="form-control" v-model="login.email" />
      </div>
      <div class="form-group">
        <label>Senha: </label>
        <password-input
          v-model="login.password"
          custom_style="text-align:center;"
          @enter_key_pressed="submit"
        />
      </div>
      <small>{{ login_error }}</small>
      <button class="btn btn-success" @click="submit">Entrar</button>
    </div>
  </div>
</template>
<script type="text/javascript">
import { mapActions, mapState } from 'vuex';
import passwordInput from '@/features/passwordInput/PasswordInput.vue';

export default {
  components: {
    passwordInput,
  },
  data() {
    return {
      login: {
        email: '',
        password: '',
      },
      login_error: '',
    };
  },
  methods: {
    ...mapActions('auth', ['ActionLogin']),
    async submit() {
      try {
        this.login_error = '';
        await this.ActionLogin({
          email: this.login.email,
          password: this.login.password,
        });
        this.$router.push('/');
      } catch (error) {
        this.login_error = error.response.data;
      }
    },
  },
  computed: {
    ...mapState('auth', ['user', 'token']),
  },
};
</script>
<style lang="scss" scoped>
.logo-container{
  width: 100%;
  padding-bottom:30px;
  img {
    width:70%;
    height: auto;
  }
}
.login-page {
  background: url("../../assets/images/login_background.jpg");
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-box {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
  max-width:350px;
  color: white;
  text-align: center;
}
h2 {
  margin-bottom: 30px;
}
input {
  margin-bottom: 20px;
}

.btn {
  width: 100%;
  margin-top: 30px;
}
small {
  color: red;
  font-weight: 700;
  font-size: 1rem;
}
</style>
